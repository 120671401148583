import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import { Input } from 'antd';

// components
import MainLayout from '@components/layout';

// services
import AuthTokenStorageService from '@services/AuthTokenStorageService';

const Login = ({ categories, topBlogs }) => {
  const router = useRouter();
  const [token, setToken] = useState(null);

  const setStorageAuthToken = async () => {
    if (token === null) {
      alert('token null!!!');
      return;
    }
    await AuthTokenStorageService.store(token);
    router.push('/');
  };

  const handleChangeInput = e => {
    setToken(e.target.value);
  };

  return (
    // <MainLayout>
    <div className="flex flex-col justify-center items-center mt-6 mb-10">
      <div className="font-bold text-xl mb-3">Login</div>
      <div className="mb-4">
        <Input
          placeholder="Token"
          value={token}
          onChange={handleChangeInput}
          style={{ width: 300 }}
        />
      </div>
      <button
        onClick={setStorageAuthToken}
        className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-1.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        Хадгалах
      </button>
    </div>
    // </MainLayout>
  );
};

export default Login;
